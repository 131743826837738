import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import { VStack, HStack, Text, Box, Button } from "@chakra-ui/react";
import { HTMLContent } from "../components/content";
import { getImage } from "gatsby-plugin-image";
import Quotes from "../components/quotes";
import Book3D from "../components/book3D";
import Books from "../components/allBooks";

const BookPageTemplate = ({
  title,
  subtitle,
  abstract,
  author,
  cover,
  twitterPreview,
  content,
  press,
  credits,
  readingSample,
  allBooksByAuthor,
}) => {
  const gatsbyCover = getImage(cover);
  const twitterImagePath = twitterPreview
    ? `/images/${twitterPreview.relativePath}`
    : `/images/${cover.relativePath}`;
  const readingSampleLink = `/${readingSample}`;
  const otherBooksByAuthor = allBooksByAuthor.filter(
    (book) => book.frontmatter.title !== title
  );

  return (
    <Layout title={title} image={twitterImagePath} description={abstract}>
      <VStack alignItems="center" spacing="12">
        <HStack alignItems="flex-start" justifyContent="center" wrap="wrap">
          <Box mb="8" mr="8">
            <Book3D title={title} gatsbyCover={gatsbyCover}></Book3D>
            {credits && (
              <Text fontSize="smaller" lineHeight="shorter">
                Image used in cover by<br></br>
                <a href={credits.artistLink}>{credits.artistName}</a> from{" "}
                <a href={credits.srcLink}>{credits.srcName}</a>
              </Text>
            )}
          </Box>
          <VStack alignItems="start" flexBasis="xs" flexGrow="0.5" spacing="4">
            <Text color="red.600">
              Dieser Titel ist zur Zeit leider vergriffen!
            </Text>
            {readingSample && (
              <Button as={Link} to={readingSampleLink}>
                Leseprobe
              </Button>
            )}
            <h3>{author}</h3>
            <Text as="h1" pb="0">
              {title}
            </Text>
            {subtitle && <h2>{subtitle}</h2>}
            <p>{abstract}</p>
            <HTMLContent content={content} />
            {press && <Quotes quotes={press}></Quotes>}
          </VStack>
        </HStack>
        {otherBooksByAuthor.length > 0 && (
          <Books
            books={otherBooksByAuthor}
            headline={`Weitere Titel von ${author}`}
          ></Books>
        )}
      </VStack>
    </Layout>
  );
};

BookPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  abstract: PropTypes.string,
  author: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  twitterPreview: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  press: PropTypes.array,
  credits: PropTypes.object,
  readingSample: PropTypes.string,
  allBooksByAuthor: PropTypes.array,
};

const BookPage = ({ data }) => {
  const book = data.markdownRemark;
  return (
    <BookPageTemplate
      title={book.frontmatter.title}
      subtitle={book.frontmatter.subtitle}
      abstract={book.frontmatter.abstract}
      author={book.frontmatter.author}
      cover={book.frontmatter.cover}
      press={book.frontmatter.press}
      content={book.html}
      credits={book.frontmatter.credits}
      readingSample={book.frontmatter.readingSample}
      allBooksByAuthor={data.allMarkdownRemark.nodes}
      twitterPreview={book.frontmatter.twitterPreview}
    />
  );
};

BookPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }),
};

export default BookPage;

export const bookPageQuery = graphql`
  query BookById($id: String!, $author: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        author
        abstract
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 256
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          relativePath
        }
        twitterPreview {
          relativePath
        }
        press {
          quote
          cite
        }
        credits {
          artistLink
          srcLink
          artistName
          srcName
        }
        readingSample
      }
    }
    allMarkdownRemark(filter: { frontmatter: { author: { eq: $author } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          cover {
            childImageSharp {
              gatsbyImageData(width: 156)
            }
          }
          title
        }
      }
    }
  }
`;
