import * as React from "react";

const Quote = (props) => {
  return (
    <blockquote>
      {"»"}
      {props.children}
      {"«"}
      {" - "}
      <cite>{props.cite}</cite>
    </blockquote>
  );
};

export default Quote;
