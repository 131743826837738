import { VStack } from "@chakra-ui/layout";
import * as React from "react";
import Quote from "./quote";

const QuoteList = (props) => {
  return props.quotes.map((quote) => {
    return <Quote cite={quote.cite}>{quote.quote}</Quote>;
  });
};

const Quotes = (props) => {
  return (
    <VStack spacing="4" pt="8" alignItems="start">
      <h2>Pressestimmen</h2>
      <QuoteList quotes={props.quotes} />
    </VStack>
  );
};

export default Quotes;
